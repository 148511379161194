import * as React from "react";
import {
  container,
  subtitle,
  title3,
  headerContainer,
  category,
  pageLink,
  linkContainer,
} from "./Sitemap.module.scss";
import Col from "react-bootstrap/Col";
import { Link, navigate } from "gatsby";

export const Container: React.FC<{ children: JSX.Element }> = (props) => {
  return <div className={container}>{props.children}</div>;
};

export const Subtitle: React.FC<{ children: JSX.Element }> = (props) => {
  return <div className={subtitle}>{props.children}</div>;
};

export const Title3: React.FC<{ children: JSX.Element }> = (props) => {
  return <div className={title3}>{props.children}</div>;
};

export const HeaderContainer: React.FC<{ children: JSX.Element }> = (props) => {
  return <div className={headerContainer}>{props.children}</div>;
};

export const Category: React.FC<{ to: string; children: JSX.Element }> = (
  props
) => {
  return (
    <div className={category} onClick={() => navigate(props.to)}>
      {props.children}
    </div>
  );
};

export const PageLink: React.FC<{ to: string; children: JSX.Element }> = (
  props
) => {
  return (
    <Link to={props.to} className={pageLink}>
      {props.children}
    </Link>
  );
};

const transformData = (rawData: any) => {
  const result = [];
  const maxElements = 6; // max elemtents per column
  let count = 0;
  let pushIndex = 0;
  for (let i = 0; i < rawData.length; i++) {
    if (count < maxElements) {
      if (result[pushIndex]) {
        result[pushIndex] = [...result[pushIndex], rawData[i]];
      } else {
        result[pushIndex] = [rawData[i]];
      }
      count = count + (rawData[i].field_sitemap_links?.length || 0) + 1;
    } else {
      result[pushIndex + 1] = [rawData[i]];
      pushIndex++;
      count = (rawData[i].field_sitemap_links?.length || 0) + 1;
    }
  }
  return result;
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const renderSiteMapLinks = (siteMapData: any): JSX.Element[] => {
  const transformedData = transformData(siteMapData);
  return transformedData.map((colElms, index) => {
    return (
      <Col lg={4} key={index}>
        {colElms.map((section) => {
          return (
            <>
              <div>
                <Category to={section.field_sitemap_title.url}>
                  {section.field_sitemap_title.title}
                </Category>
              </div>
              <ul
                className={`${linkContainer} list-unstyled`}
                key={section.field_sitemap_title.title}
              >
                {section.field_sitemap_links?.map(
                  ({ url, title }: { url: string; title: JSX.Element }) => {
                    return (
                      <li key={title}>
                        <PageLink to={url}>{title}</PageLink>
                      </li>
                    );
                  }
                )}
              </ul>
            </>
          );
        })}
      </Col>
    );
  });
};
