import * as React from "react";
import { graphql } from "gatsby";
import { ISitemapProps } from "../pageHelpers/Sitemap/SitemapProps";
import {
  Container,
  Subtitle,
  Title3,
  HeaderContainer,
  renderSiteMapLinks,
} from "../pageHelpers/Sitemap/SitemapHelpers";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Layout from "../components/global/Layout/Layout";
import SEO from "../components/global/SEO/SEO";
// import SocialMedia from "../components/common/SocialMedia/SocialMedia";
import { getParagraph } from "../utils/paragraphHelpers";

const Sitemap: React.FC<ISitemapProps> = (props: ISitemapProps) => {
  const paragraphs =
    props.data?.page?.relationships?.paragraphs.map(getParagraph);
  const siteMapData =
    props.data?.allParagraphSitemapList?.nodes[0].relationships.field_sections;
  return (
    <Layout>
      <SEO
        title={props.data.page?.title}
        description={props.data.page?.field_meta_description}
      />
      <h1 className="visually-hidden">Sonesta Hotels Sitemap</h1>
      <Container fluid="sm" className="pt-4 pb-4 pt-lg-5 pb-lg-5">
        <Row>
          <Col lg={3} className="text-center text-lg-left mb-3 ">
            <HeaderContainer>
              <Subtitle>SITEMAP</Subtitle>
              <Title3>
                Sonesta Hotels <br />
                Sitemap
              </Title3>
            </HeaderContainer>
          </Col>
          <Col lg={{ span: 8, offset: 1 }}>
            <Row className="sitemap-links text-left text-sm-center ">
              {renderSiteMapLinks(siteMapData)}
            </Row>
          </Col>
        </Row>
      </Container>
      {paragraphs}
      {/* <SocialMedia /> */}
    </Layout>
  );
};
export default Sitemap;

export const pageQuery = graphql`
  query SitemapQuery {
    page: nodePage(path: { alias: { eq: "/v1/sitemap" } }) {
      id
      title
      field_meta_description
      relationships {
        paragraphs: field_sections {
          type: __typename
          ...ParagraphMediaCta
        }
      }
    }
    allParagraphSitemapList: allNodePage(
      filter: { path: { alias: { eq: "/v1/sitemap" } } }
    ) {
      nodes {
        relationships {
          field_sections {
            ... on paragraph__sitemap_list {
              id
              field_sitemap_title {
                title
                url
              }
              field_sitemap_links {
                title
                url
              }
            }
          }
        }
      }
    }
  }
`;
